import * as ShipmentsActions from './shipments.actions';
import * as ShipmentsSelectors from './shipments.selectors';

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ShipmentsHTTPService} from '../_services/shipments.HTTP.service';
import {catchError, map, mergeMap, of, switchMap, withLatestFrom} from 'rxjs';
import {Store} from '@ngrx/store';
import {PageableResponse} from '@common/_models/core.models';
import {BrokerLoadModel} from '../../../../../services/broker/model/brokerLoadModel';
import {StoredComponentHelperService} from '@common/_services/stored-component.helper.service';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class ShipmentsEffects {

    loadList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ShipmentsActions.LoadList),
            switchMap((action) => of(action).pipe(
                withLatestFrom(
                    this.store$.select(ShipmentsSelectors.selectFilter(action.payload.pageType)),
                    this.store$.select(ShipmentsSelectors.selectPagination(action.payload.pageType))
                )
            )),
            switchMap(([action, filter, pagination]) => {
                return this.apiService.getList({...filter, statuses: [action.payload.pageType]}, pagination).pipe(
                    map((response: PageableResponse<BrokerLoadModel>) => ShipmentsActions.LoadListSuccess({
                        payload: {
                            pageType: action.payload.pageType,
                            items: response.content!,
                            pagination: StoredComponentHelperService.cleanPaginationResponse(response),
                            clearState: action.payload.clearState
                        }
                    })),
                    catchError((error: HttpErrorResponse) => of(ShipmentsActions.LoadListFailure({
                        payload: {
                            pageType: action.payload.pageType,
                            error
                        }
                    })))
                );
            })
        );
    });

    deleteItem$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ShipmentsActions.DeleteItem),
            mergeMap((action) => {
                return this.apiService.deleteItem(action.payload.item.id!).pipe(
                    map(() => ShipmentsActions.DeleteItemSuccess({
                        payload: {
                            pageType: action.payload.pageType,
                            item: action.payload.item
                        }
                    })),
                    catchError(() => of(ShipmentsActions.SetItemsLoading({
                        payload: {
                            pageType: action.payload.pageType,
                            id: action.payload.item.id!,
                            isStart: false
                        }
                    })))
                );
            })
        );
    });

    constructor(
        private actions$: Actions,
        private store$: Store,
        private apiService: ShipmentsHTTPService
    ) {
    }
}
