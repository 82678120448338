import * as CoreActions from '@common/_store/core.actions';

import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, of, switchMap, tap} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {DictionariesService} from '@common/_services/dictionaries.service';
import {CustomerResponse} from '../../../../services/dictionaries/model/customerResponse';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()

export class CoreEffects {

    httpError$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CoreActions.HttpErrorDetected),
            tap((action) => {
                const hasTranslation =
                    (this.translateService.instant(`errors.${action.key}.text`) != `errors.${action.key}.text` || this.translateService.instant(`errors.${action.key}.title`) != `errors.${action.key}.title`);
                if (hasTranslation) {
                    let text = this.translateService.instant('errors.' + action.key + '.text', {data: action.data?.name});
                    if ((action.key == 'sms_gateway_error' || action.key == 'smtp_server_error') && action.data) {
                        text = action.data;
                    }
                    this.toastrService.error(text, this.translateService.instant('errors.' + action.key + '.title'), {
                        enableHtml: true,
                        timeOut: 5000
                    });
                } else {
                    this.toastrService.error(this.translateService.instant('errors.internal_error.text'), this.translateService.instant('errors.internal_error.title'));
                }
            })
        );
    }, {dispatch: false});

    /** Edit Profile */
    editProfile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CoreActions.EditProfile),
            switchMap((action) => {
                return this.dictionariesService.editProfile(action.payload).pipe(
                    map((result: CustomerResponse) => CoreActions.EditProfileSuccess({payload: result})),
                    catchError((error: HttpErrorResponse) => of(CoreActions.EditProfileFail({payload: error})))
                );
            })
        );
    });

    editProfileSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CoreActions.EditProfileSuccess),
            tap(() => {
                this.toastrService.success(this.translateService.instant('toolbar.editProfileDialog.toastr.editSuccess.text'), this.translateService.instant('toolbar.editProfileDialog.toastr.editSuccess.title'));
            })
        );
    }, {dispatch: false});

    editProfileFail$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CoreActions.EditProfileFail)
        );
    }, {dispatch: false});

    constructor(
        private actions$: Actions,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        private dictionariesService: DictionariesService
    ) {
    }
}
