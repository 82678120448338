import {Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {UiPageComponent} from './ui-page/ui-page.component';

export const routes: Routes = [
    {
        path: '',
        component: LoginComponent,
    },
    {
        path: 'ui-page',
        component: UiPageComponent
    },
    {
        path: 'cabinet',
        loadChildren: () => import('./cabinet/cabinet.module').then(mod => mod.CabinetModule)
    },
    {
        path: 'tracking/:token',
        loadComponent: () => import('./tracking-component/tracking-component.component').then(cmp => cmp.TrackingComponentComponent)
    },
    {
        path: '**',
        redirectTo: '',
    }
];
