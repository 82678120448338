import * as ConversationActions from '@conversation/_store/conversation.actions';

import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, of, switchMap} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {CustomerChatModel} from '../../../../../services/chat/model/customerChatModel';
import {MessengerService} from '@common/_services/messenger.service';

@Injectable()
export class ConversationEffects {
    loadCustomerChats$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ConversationActions.LoadConversations),
            switchMap(() => {
                return this.messengerService.getAllConversations().pipe(
                    map((res: CustomerChatModel[]) => ConversationActions.LoadConversationsSuccess({payload: res})),
                    catchError((error: HttpErrorResponse) => of(ConversationActions.LoadConversationsFailure({payload: error})))
                );
            })
        );
    });

    markChatAsRead$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ConversationActions.MarkChatAsRead),
            switchMap((action) => {
                return this.messengerService.readConversation(action.payload).pipe(
                    map((res: CustomerChatModel) => ConversationActions.UpdateChatSuccess({payload: res})),
                    catchError((error: HttpErrorResponse) => of(ConversationActions.MarkChatAsReadFailure({payload: error})))
                );
            })
        );
    });

    constructor(
        private actions$: Actions,
        private messengerService: MessengerService
    ) {
    }
}
