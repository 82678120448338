import {coreReducer, CoreState, coreStateFeatureKey} from '@common/_store/core.reducer';
import {ActionReducerMap} from '@ngrx/store';
import {shipmentsFeatureKey, shipmentsReducer, ShipmentsState} from './cabinet/shipments/_store/shipments.reducer';
import {usersFeatureKey, usersReducer, UsersState} from './cabinet/users/_store/users.reducer';
import {loadMapFeatureKey, loadMapReducer, LoadMapState} from './cabinet/load-map/_store/load-map.reducer';
import {unitMapFeatureKey, unitMapReducer, UnitMapState} from './cabinet/unit-map/_store/unit-map.reducer';
import {conversationFeatureKey, conversationReducer, ConversationState} from '@conversation/_store/conversation.reducer';

export interface OvnReducers {
    [coreStateFeatureKey]: CoreState,
    [conversationFeatureKey]: ConversationState,
    [shipmentsFeatureKey]: ShipmentsState
    [usersFeatureKey]: UsersState,
    [loadMapFeatureKey]: LoadMapState,
    [unitMapFeatureKey]: UnitMapState
}

export const reducers: ActionReducerMap<OvnReducers> = {
    [coreStateFeatureKey]: coreReducer,
    [conversationFeatureKey]: conversationReducer,
    [shipmentsFeatureKey]: shipmentsReducer,
    [usersFeatureKey]: usersReducer,
    [loadMapFeatureKey]: loadMapReducer,
    [unitMapFeatureKey]: unitMapReducer,
};
