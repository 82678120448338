import {inject, isDevMode, LOCALE_ID, NgModule, provideAppInitializer} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {routes} from './app.routes';
import {RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ToastrModule} from 'ngx-toastr';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {StoreModule} from '@ngrx/store';
import {reducers} from './app.reducers';
import {TokenInterceptor} from '@common/_interceptors/token.interceptor';
import {RefreshTokenInterceptor} from '@common/_interceptors/refresh-token.interceptor.service';
import {CoreService} from '@common/_services/core.service';
import {CoreEffects} from '@common/_store/core.effects';
import {ShipmentsEffects} from './cabinet/shipments/_store/shipments.effects';
import {MatNativeDateModule} from '@angular/material/core';
import {provideEnvironmentNgxMask} from 'ngx-mask';
import {ConversationEffects} from '@conversation/_store/conversation.effects';

function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + Date.now());
}

function initializeToken(core: CoreService) {
    return new Promise((resolve) => {
        core.initializeApp();
        core.setTabId();
        resolve(true);
    });
}

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatNativeDateModule,
        RouterModule.forRoot(routes),
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({maxAge: 25, logOnly: !isDevMode()}),
        EffectsModule.forRoot([
            CoreEffects,
            ShipmentsEffects,
            ConversationEffects
        ]),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: 'en',
            useDefaultLang: true
        }),
        ToastrModule.forRoot({
            closeButton: true,
            timeOut: 3000,
            enableHtml: true,
            tapToDismiss: false,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
            maxOpened: 5,
            toastClass: 'ovn-toast',
            iconClasses: {
                error: 'ovn-toast-error',
                info: 'ovn-toast-info',
                success: 'ovn-toast-success',
                warning: 'ovn-toast-warning',
            },
        }),
    ],
    providers: [
        provideAppInitializer(() => initializeToken(inject(CoreService))),
        provideHttpClient(withInterceptorsFromDi()),
        provideEnvironmentNgxMask(),
        {
            provide: LOCALE_ID,
            useValue: 'en-US'
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshTokenInterceptor,
            multi: true,
        }
    ]
})

export class AppModule {

}
