import { Component } from '@angular/core';

@Component({
  selector: 'ovn-customer-cabinet',
  standalone: false,
  template: '<router-outlet/>'
})

export class AppComponent {
  title = 'ovn-customer-cabinet';
}
