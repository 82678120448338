<div class="login-page">

    <div class="promo-block">
        <img class="promo-image" src="../assets/icons/auth/promo_image.svg" alt="">
        <div class="promo-block-header">
            <div class="logo">
                <img src="../assets/icons/auth/white_logo.svg" alt="">
            </div>
            <div (click)="goToHomepage()" class="back-button">
                <img ngSrc="../assets/icons/auth/arrow_back.svg" alt="" height="26" width="35">
                <span>{{ 'logIn.buttons.back' | translate | titlecase }}</span>
            </div>
        </div>
        <div class="promo-block-content">
            <div class="content-header">{{ 'logIn.promoBlock.contentHeader' | translate }}</div>
            <div class="content-description">{{ 'logIn.promoBlock.contentDescription' | translate | titlecase }}</div>
            <div class="content-items">
                <div class="content-item">
                    <canvas></canvas>
                    <span>{{ 'logIn.promoBlock.contentItem1' | translate | titlecase }}</span>
                </div>
                <div class="content-item">
                    <canvas></canvas>
                    <span>{{ 'logIn.promoBlock.contentItem2' | translate | titlecase }}</span>
                </div>
                <div class="content-item">
                    <canvas></canvas>
                    <span>{{ 'logIn.promoBlock.contentItem3' | translate | titlecase }}</span>
                </div>
                <div class="content-item">
                    <canvas></canvas>
                    <span>{{ 'logIn.promoBlock.contentItem4' | translate | titlecase }}</span>
                </div>
                <div class="content-item">
                    <canvas></canvas>
                    <span>{{ 'logIn.promoBlock.contentItem5' | translate | titlecase }}</span>
                </div>
                <div class="content-item">
                    <canvas></canvas>
                    <span>{{ 'logIn.promoBlock.contentItem6' | translate | titlecase }}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="form-block">
        <div (click)="goToHomepage()" class="back-button">
            <img ngSrc="../assets/icons/auth/arrow_back.svg" alt="" height="26" width="35">
            <span>{{ 'logIn.buttons.back' | translate | titlecase }}</span>
        </div>
        <div class="login-header">
            {{ 'logIn.title' | translate }}
        </div>
        <form [formGroup]="loginForm" class="login-form gap30">
            <mat-form-field appearance="outline" class="mdc-field-medium full-width" floatLabel="always">
                <mat-label>{{ 'logIn.form.formLabels.login' | translate | titlecase }}</mat-label>
                <input (keydown.enter)="submitForm()" [required]="true" autocomplete="off" formControlName="login" matInput type="text">
            </mat-form-field>

            <mat-form-field appearance="outline" class="mdc-field-medium full-width" floatLabel="always">
                <mat-label>{{ 'logIn.form.formLabels.password' | translate | titlecase }}</mat-label>
                <input (keydown.enter)="submitForm()" [required]="true" [type]="hidePass ? 'password' : 'text'" formControlName="password" matInput>
                <button (click)="hidePass = !hidePass" *ngIf="loginForm.get('password')?.value"
                        [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hidePass" class="icon-button default" matSuffix>
                    <mat-icon class="default-icon">{{ hidePass ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
            </mat-form-field>
        </form>

        <div class="buttons-row login-buttons full-width">
            <div (click)="forgotPassword()" [ngClass]="showRestorePasswordLink ? '' : 'invisible'" class="forgot-password-link">
                <span>{{ 'buttons.forgotPassword' | translate | titlecase }}</span>
            </div>
            <button (click)="submitForm()" [disabled]="loginForm.invalid || (loading$ | async) === true" class="btn primary spinner-button">
                <span *ngIf="(loading$ | async) === false">{{ 'logIn.buttons.signIn' | translate | titlecase }}</span>
                <mat-spinner *ngIf="(loading$ | async) === true" diameter="30"></mat-spinner>
            </button>
        </div>
        <!--    <div class="create-account-link" (click)="goToRegistration()">-->
        <!--      <span>{{'buttons.createAccount' | translate | firstUpperCase}}</span>-->
        <!--      <img src="../assets/icons/auth/arrow_right.svg">-->
        <!--    </div>-->
    </div>

</div>
