import {Component, OnInit} from '@angular/core';
import {FormBuilder, UntypedFormGroup} from '@angular/forms';
import {AngularComponentsModule} from '@common/angular-components.module';
import {AuthenticationService} from '@common/_services/authentication.service';
import {BehaviorSubject, catchError, of, take} from 'rxjs';
import {LoginRequestDto} from '../../../services/authentication/model/loginRequestDto';
import {CoreService} from '@common/_services/core.service';
import {ConfigService} from '@common/_services/config.service';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
@Component({
    selector: 'ovn-login',
    standalone: true,
    imports: [
        AngularComponentsModule
    ],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
    public loginForm: UntypedFormGroup;

    public userEmail: string;
    public hidePass: boolean;
    public showRestorePasswordLink: boolean;
    public loading$ = new BehaviorSubject<boolean>(false);

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthenticationService,
        private configService: ConfigService,
        private coreService: CoreService,
        private router: Router
    ) {
    }


    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            login: 'simple-user@gmail.com',
            password: '123456'
        });
    }

    goToHomepage() {
        window.open(environment.homepageUrl);
    }

    submitForm() {
        if (this.loginForm.invalid) {
            return;
        }

        const formData = this.loginForm.getRawValue();
        this.userEmail = formData && formData.login ? formData.login : '';

        const request: LoginRequestDto = {
            login: formData.login,
            password: formData.password,
            deviceId: this.configService.deviceAndBrowserUuid.randomUUID,
            clientId: this.configService.authCredentials.clientId,
            clientType: this.configService.authCredentials.clientType,
        };

        this.loading$.next(true);
        this.coreService.login(request).pipe(
            take(1),
            catchError(() => {
                this.loading$.next(false);
                return of();
            })
        ).subscribe(() => {
            this.loading$.next(false);
            this.router.navigate(['cabinet/shipments/in-progress']).then();
        });
    }

    forgotPassword() {

    }
}
