import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {filter, Observable, take} from "rxjs";
import {CoreService} from '@common/_services/core.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private tabId: string | undefined;

    constructor(
        private coreService: CoreService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken: string | null = localStorage ? localStorage.getItem('accessToken') : null;
        const isPublicApi = request.headers.keys()?.includes('Public');

        this.coreService.selectTabId$().pipe(
            filter(data => !!data),
            take(1)
        ).subscribe((tabId: string | undefined) => {
            this.tabId = tabId;
        });

        if (!isPublicApi && accessToken !== null) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken}`,
                    'X-Ovn-TabId': this.tabId ? this.tabId : ''
                },
            });
        }

        return next.handle(request);
    }
}
