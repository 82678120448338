import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, exhaustMap, filter, Observable, switchMap, take, throwError} from 'rxjs';
import {CoreService} from '@common/_services/core.service';
import {ReloginRequestDto} from '../../../../services/authentication/model/reloginRequestDto';
import {ConfigService} from '@common/_services/config.service';
import {LocalStorageService} from '@common/_services/local-storage.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private coreService: CoreService,
        private configService: ConfigService,
        private lsService: LocalStorageService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status !== 401) {

                    if (error.error?.message) {
                        this.coreService.proceedHTTPError(error, error.error.message, error.error?.data);
                    }

                    if (error?.error && (request instanceof Blob || request.responseType == 'blob')) {
                        (error.error as Blob).text().then(res => {
                            if (res) {
                                const err = JSON.parse(res);
                                err?.message && this.coreService.proceedHTTPError(error, err.message, err.data);
                            }
                        });
                    }

                    if (error?.error && request.responseType == 'arraybuffer') {
                        const enc = new TextDecoder("utf-8");
                        const dec = enc.decode(error.error);
                        const err = JSON.parse(dec);
                        err?.message && this.coreService.proceedHTTPError(error, err.message, err.data);
                    }

                    return throwError(() => error);
                }

                // Call auth.refreshAccessToken(this is an Observable that will be returned)
                const refreshToken: string | null = this.lsService.get('refreshToken');
                const deviceId: string | null = this.lsService.get('deviceId');

                if (!refreshToken || !deviceId) {
                    return this.coreService.navigateToLogin(true);
                }

                if (this.refreshTokenInProgress) {
                    return this.refreshTokenSubject.pipe(
                        filter(result => result !== null),
                        take(1),
                        switchMap(() => next.handle(this.addAuthenticationToken(request)))
                    );
                } else {

                    this.refreshTokenInProgress = true;
                    // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                    this.refreshTokenSubject.next(null);

                    const reLoginRequest: ReloginRequestDto = {
                        clientId: this.configService.authCredentials.clientId,
                        clientType: this.configService.authCredentials.clientType,
                        refreshToken,
                        deviceId
                    };

                    return this.coreService.reLogin(reLoginRequest).pipe(
                        exhaustMap((token: any) => {
                            this.refreshTokenSubject.next(token);
                            this.refreshTokenInProgress = false;
                            return next.handle(this.addAuthenticationToken(request));
                        }),
                        catchError((error: HttpErrorResponse) => {
                            this.coreService.navigateToLogin(true);
                            this.refreshTokenInProgress = false;
                            return throwError(() => error);
                        })
                    );
                }
            })
        );
    }

    private addAuthenticationToken(request: HttpRequest<any>) {
        // Get access token from Local Storage
        const accessToken = localStorage ? localStorage.getItem('accessToken') : null;

        // If access token is null this means that user is not logged in
        // And we return the original request
        if (!accessToken) {
            return request;
        }

        // We clone the request, because the original request is immutable
        return request.clone({
            setHeaders: {
                Authorization: 'Bearer ' + accessToken,
            },
        });
    }
}
